import React, { Component } from 'react';
// import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { doRegistration } from '../actions/authentication-actions';

import BannerRow from '../components/pages/BannerRow';
import Loader from '../components/Loader';
import TagManager from 'react-gtm-module';

import { validatePostcode } from '../services/postcode';
import { formatBritishPostcode } from '../utils';

const isIrelandVisitor = ('' + document.location).search('.ie') >= 0;
class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: isIrelandVisitor ? null : 'consumer',
      error: false,
      postcodeError: false,
      country: '',
      loading: false,
      success: false,
    };

    this.renderType = this.renderType.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderThanks = this.renderThanks.bind(this);

    this.setType = this.setType.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onPostcodeChange = this.onPostcodeChange.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false });
  }

  handleCountryChange(event) {
    this.setState({
      country: event.target.value,
      postcodeError: event.target.value === 'ireland' ? false : this.state.postcodeError,
    });
  }

  onPostcodeChange(event) {
    this.refs.postcode.value = formatBritishPostcode(this.refs.postcode.value);
  }

  onSubmit(event) {
    event.preventDefault();

    const { type } = this.state;

    // Check if its valid or not
    if (event.target.checkValidity() !== false) {
      this.setState({
        error: false,
        loading: true,
        success: false,
      });

      // Build the data
      const data = {
        type: this.state.type,
        title: this.refs.title.value,
        lastName: this.refs.lastName.value,
        firstName: this.refs.firstName.value,
        email: this.refs.email.value,
        postcode: this.refs.postcode ? this.refs.postcode.value : '',
        county: this.refs.county ? this.refs.county.value : '',
        country: this.refs.country.value,
        marketingEducationContentEmail: this.refs.marketingEducationContentEmail.checked,
        marketingEducationContentApp: this.refs.marketingEducationContentApp.checked,
        marketingFeedbackSurveysEmail: this.refs.marketingFeedbackSurveysEmail.checked,
        marketingFeedbackSurveysApp: this.refs.marketingFeedbackSurveysApp.checked,
      };

      // If its hcp, add on the job role
      if (type === 'hcp') {
        data.jobRole = this.refs.jobRole.value;
      }

      // Consumer specific data
      if (type === 'consumer') {
        data.marketingOffersAndPromotionsEmail = this.refs.marketingOffersAndPromotionsEmail.checked;
        data.marketingOffersAndPromotionsApp = this.refs.marketingOffersAndPromotionsApp.checked;
      }

      if (data.country === 'uk') {
        validatePostcode(data.postcode).then(response => {
          if (!response.result) {
            this.setState({
              postcodeError: true,
              loading: false,
            });
          } else {
            // Do registration
            this.props.doRegistration(data).then(response => {
              if (response.error) {
                this.setState({
                  error: response.error,
                  postcodeError: false,
                  loading: false,
                  success: false,
                });
              } else {
                this.setState({
                  error: false,
                  postcodeError: false,
                  loading: false,
                  success: true,
                });
              }
            });
          }
        });
      } else {
        // For Ireland
        this.props.doRegistration(data).then(response => {
          if (response.error) {
            this.setState({
              error: response.error,
              postcodeError: false,
              loading: false,
              success: false,
            });
          } else {
            this.setState({
              error: false,
              postcodeError: false,
              loading: false,
              success: true,
            });
          }
        });
      }
    }

    // This is required for client-side validation feedback with bootstrap
    event.target.classList.add('was-validated');
  }

  setType(event) {
    this.setState({ type: event.target.dataset.type });
  }

  renderType() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <h3 className="text-center">TELL US WHO YOU ARE</h3>
          <div className="circle" data-type="consumer" onClick={this.setType}>
            <div>Consumer</div>
          </div>
          <div className="circle" data-type="hcp" onClick={this.setType}>
            <div>Healthcare Professional</div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const { country, type, postcodeError } = this.state;

    const counties = [
      'Co. Carlow',
      'Co. Cavan',
      'Co. Clare',
      'Co. Cork',
      'Co. Donegal',
      'Co. Dublin North',
      'Co. Dublin South',
      'Co. Dublin West',
      'Co. Galway',
      'Co. Kerry',
      'Co. Kildare',
      'Co. Kilkenny',
      'Co. Laois',
      'Co. Leitrim',
      'Co. Limerick',
      'Co. Longford',
      'Co. Louth',
      'Co. Mayo',
      'Co. Meath',
      'Co. Monaghan',
      'Co. Offaly',
      'Co. Roscommon',
      'Co. Sligo',
      'Co. Tipperary',
      'Co. Waterford',
      'Co. Westmeath',
      'Co. Wexford',
      'Co. Wicklow',
      'Dublin 1',
      'Dublin 2',
      'Dublin 3',
      'Dublin 4',
      'Dublin 5',
      'Dublin 6',
      'Dublin 6W',
      'Dublin 7',
      'Dublin 8',
      'Dublin 9',
      'Dublin 10',
      'Dublin 11',
      'Dublin 12',
      'Dublin 13',
      'Dublin 14',
      'Dublin 15',
      'Dublin 16',
      'Dublin 17',
      'Dublin 18',
      'Dublin 19',
      'Dublin 20',
      'Dublin 22',
      'Dublin 24',
    ];

    let countiesList = counties.map(function (county, i) {
      return (
        <option key={`county-${i}`} value={county}>
          {county}
        </option>
      );
    });

    return (
      <div className="inner-block bg-light">
        <form className="needs-validation bg-faded" onSubmit={this.onSubmit} noValidate>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="registerTitle">
              * Title
            </label>
            <div className="col-sm-8">
              <select className="form-control" id="registerTitle" ref="title">
                <option value="master">Master</option>
                <option value="mr">Mr</option>
                <option value="miss">Miss</option>
                <option value="mrs">Mrs</option>
                <option value="ms">Ms</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="registerFirstName">
              * First Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                id="registerFirstName"
                placeholder="First Name"
                ref="firstName"
                autoComplete="given-name"
                required
              />
              <div className="invalid-feedback">Please enter your first name.</div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="registerLastName">
              * Last Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                id="registerLastName"
                placeholder="Last Name"
                ref="lastName"
                autoComplete="family-name"
                required
              />
              <div className="invalid-feedback">Please enter your last name.</div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="registerEmail">
              * Email
            </label>
            <div className="col-sm-8">
              <input
                type="email"
                className="form-control"
                id="registerEmail"
                placeholder="Email"
                ref="email"
                autoComplete="email"
                required
              />
              {type === 'hcp' && (
                <small className="form-text text-muted">
                  In order to verify you as a healthcare professional, please use either your NHS, HSE or institution email address.
                </small>
              )}
              <div className="invalid-feedback">Please enter your email address.</div>
            </div>
          </div>
          {country === 'ireland' && (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="registerCounty">
                * County
              </label>
              <div className="col-sm-8">
                <select required className="form-control" id="registerCounty" ref="county">
                  <option value="" disabled selected>
                    Please Select County
                  </option>
                  {countiesList}
                </select>
                <div className="invalid-feedback">Please enter your county.</div>
              </div>
            </div>
          )}
          {country !== 'ireland' && (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="registerPostcode">
                * Postcode
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  maxLength="8"
                  onBlur={this.onPostcodeChange}
                  className={`form-control ${postcodeError && country !== 'ireland' ? 'is-invalid' : ''}`}
                  id="registerPostcode"
                  placeholder="Postcode"
                  ref="postcode"
                  autoComplete="postal-code"
                  required={country !== 'ireland' ? true : false}
                />
                <div className="invalid-feedback">Please enter a valid postcode.</div>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="myAccountJobRole">
              * Country
            </label>
            <div className="col-sm-8">
              <select required className="form-control" id="registerCountry" ref="country" onChange={this.handleCountryChange}>
                <option value="" disabled selected>
                  Select Your Country
                </option>
                {type === 'consumer' && <option value="uk">United Kingdom</option>}
                <option value="ireland">Ireland</option>
              </select>
              <div className="invalid-feedback">Please enter your country.</div>
            </div>
          </div>
          {type === 'hcp' && (
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="myAccountJobRole">
                * Job Role
              </label>
              <div className="col-sm-8">
                <select required className="form-control" id="myAccountJobRole" ref="jobRole">
                  <option value="" disabled selected>
                    Select Your Job Role
                  </option>
                  <option value="diabetes-specialist-nurse">Diabetes specialist nurse</option>
                  <option value="paediatric-nurse">Paediatric nurse</option>
                  <option value="pharmacist">Pharmacist</option>
                  <option value="practice-nurse">Practice nurse</option>
                  <option value="gp">GP</option>
                  <option value="consultant">Consultant</option>
                  <option value="other">Other healthcare professional</option>
                </select>
                <div className="invalid-feedback">Please enter your job role.</div>
              </div>
            </div>
          )}

          <div className="row">
            <label className="col-sm-12">Please let us know below how you would like to be notified:</label>
          </div>

          <div className="form-group row">
            <div className="col-sm-4">Educational Content</div>
            <div className="col-sm-8">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="marketingEducationContentEmail"
                  ref="marketingEducationContentEmail"
                />
                <label className="custom-control-label" htmlFor="marketingEducationContentEmail">
                  Email
                </label>
              </div>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="marketingEducationContentApp"
                  ref="marketingEducationContentApp"
                />
                <label className="custom-control-label" htmlFor="marketingEducationContentApp">
                  App
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">Feedback Surveys</div>
            <div className="col-sm-8">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="marketingFeedbackSurveysEmail"
                  ref="marketingFeedbackSurveysEmail"
                />
                <label className="custom-control-label" htmlFor="marketingFeedbackSurveysEmail">
                  Email
                </label>
              </div>
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="marketingFeedbackSurveysApp"
                  ref="marketingFeedbackSurveysApp"
                />
                <label className="custom-control-label" htmlFor="marketingFeedbackSurveysApp">
                  App
                </label>
              </div>
            </div>
          </div>
          {type === 'consumer' && (
            <div className="form-group row">
              <div className="col-sm-4">Offers and Promotions</div>
              <div className="col-sm-8">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="marketingOffersAndPromotionsEmail"
                    ref="marketingOffersAndPromotionsEmail"
                  />
                  <label className="custom-control-label" htmlFor="marketingOffersAndPromotionsEmail">
                    Email
                  </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="marketingOffersAndPromotionsApp"
                    ref="marketingOffersAndPromotionsApp"
                  />
                  <label className="custom-control-label" htmlFor="marketingOffersAndPromotionsApp">
                    App
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row privacy">
            <div className="col-sm-4">
              * I agree with{' '}
              <a href="/privacy-policy" target="_blank">
                Abbott’s Privacy Policy
              </a>
            </div>
            <div className="col-sm-8">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input className="custom-control-input" type="checkbox" id="privacyAgreement" required />
                <label className="custom-control-label" htmlFor="privacyAgreement"></label>
                <div className="invalid-feedback">Please confirm that you accept the privacy policy to proceed</div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-8 offset-sm-4">
              <button type="submit" className="btn btn-orange">
                Submit
                {this.state.loading && <Loader />}
              </button>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-8 offset-sm-4">
              <small className="form-text text-muted">(*) Required fields are indicated with an asterisk.</small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-8 offset-sm-4">
              {this.state.error && (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  <div dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                  <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderThanks() {
    // GTM 'register' event
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        category: 'Registration',
        action: 'Form Submit',
        label: this.state.type,
      },
    });
    console.log('GA event: User registered successfully in ' + this.state.type);

    return (
      <div className="thanks">
        <img src={process.env.PUBLIC_URL + '/icons/icon-registration-success.svg'} alt="Registration Complete Icon" />
        <h3>REGISTRATION COMPLETE</h3>
        <p>
          You will shortly receive an email with a link to confirm your email address and to provide you with the password for your account.
          Please check your junk mail box if you do not receive the email.
        </p>
      </div>
    );
  }

  render() {
    const content = this.state.success ? this.renderThanks() : this.state.type ? this.renderForm() : this.renderType();

    const banner = {
      content:
        "<h1>REGISTER NOW</h1><p>Register now to access FreeStyle Progress. Already have an account? <a href='/sign-in'>Login here</a></p>",
    };

    return (
      <div className="page register">
        <BannerRow banner={banner} />
        <div className="pt-3 pb-3 container">
          <div className="row">
            <div className="outer-block col-12 bg-dark">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    doRegistration,
  })(RegisterPage),
);
