import 'es6-string-polyfills';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'isomorphic-fetch';
import 'html5shiv';
import 'core-js';

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './containers/Root';
import configureStore from './store/configureStore';
import TagManager from 'react-gtm-module';

/*
import {
  heartbeatCheck,
  isMobile,
} from './utils';
*/

import 'popper.js';
import 'bootstrap';
import './stylesheets/main.scss';

// GTM initilization
TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_KEY });
console.log('GTM initialised with:', process.env.REACT_APP_GTM_KEY);

const store = configureStore();

// Do heartbeat check
/*
if (isMobile()) {
  heartbeatCheck(store);
}
*/
render(
  <Router>
    <Root store={store} />
  </Router>,
  document.getElementById('root'),
);

var script = document.createElement('SCRIPT');
var domainUrl = '' + document.location;
var cookieKey = domainUrl.search('.ie') >= 0 ? process.env.REACT_APP_COOKIE_IE_KEY : process.env.REACT_APP_COOKIE_UK_KEY;
script.src = 'https://consent.trustarc.com/v2/notice/' + cookieKey;
document.getElementsByTagName('head')[0].appendChild(script);
