import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired
  }

  componentWillMount() {}

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Route {...rest} render={(props) => (
        this.props.authentication.isAuthenticated === true
          ? <Component {...props} />
          : <Redirect to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }} />
      )} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication
  } = state

  return {
    authentication
  }
}

export default withRouter(connect(mapStateToProps, {
})(PrivateRoute))